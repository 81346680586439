//VUe3新的引入路由插件方式,分别引入
import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
// 1. index.js 是用于配置路由
// 2. path: '/', 路径  当访问 http://localhost:8080/ 就路由到 HomeView 组件
// 3. 就会把HomeView 组件的内容，返回给 <router-view/>
// 4. HomeView 组件 对应的文件就是'../views/HomeView.vue'
/**
 * 声明一个变量，使其等于createRouter函数创建的返回值
 * createRouter传入一个options参数，
 * options中包含路由表和路由模式
 * history:路由模式，对应createWebHistory()和createWebHashHistory()函数
 * createWebHistory()：history模式
 * createWebHashHistory()：hash模式
 * routes: 路由表：数组类型，里面是每个页面的path路径
 * 声明完成然后将其导出
 * @type {Router}
 */

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})
router.beforeEach((to, from, next) => {
  // 从本地存储里获取token
  let token = sessionStorage.getItem('token');
  //如果去往登录页则放行 
  if ( to.path === '/'|| to.path === '/login') {
    next()
  } else {
    // 判断token是否为空如果为空则跳转到登录页 如果有则放行
    if (!token) {
      next('/login');
    } else {
      next();
    }
  }
})
router.afterEach((to, from) => {
  document.title = to.meta.title || '系统页面'
  //判断设置当前路由是否可回退前进
  //to.meta.isBack 路由跳转后会根据这个判断
  // 一定要再afterEach中判断而不是beforeEach，因为beforeEach在点击返回之后获取到的值不准确，每返回一次，会获取到延后一次的to、history
  if (window.history.state && window.history.state.forward) { // 或者判断 to.forward,window.history.state.forward是vue-router写入的，当返回或前进的时候才会有值
    to.meta.isBack = true;
  } else {
    to.meta.isBack = false;
  }
})
export default router